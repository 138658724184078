import * as React from "react";

export default function LabelBottomNavigation({
  setIsModalOpen,
  setIsModalOpen2,
}) {
  // const [value, setValue] = React.useState("recents");

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };
  const email = localStorage.getItem("userEmail");

  return (
    <div class="w-full mt-36">
      <section
        id="bottom-navigation"
        class="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow h-20 flex items-center justify-between"
      >
        {/* <section
        id="bottom-navigation"
        class="block fixed inset-x-0 bottom-0 z-10 bg-white shadow"
      > */}
        <div id="tabs" class="flex justify-between w-full">
          <a
            href="/dashboard"
            class="w-full focus:text-teal-500 hover:text-teal-500 flex flex-col items-center justify-center inline-block text-center pt-2 pb-1"
          >
            <img
              src="https://img.icons8.com/windows/32/000000/home.png"
              width="30px"
              alt="home"
            />
            <span class="tab tab-explore block text-xs mt-1">Home</span>
          </a>

          {email !== 'njenga@neovets.com' && (
            <>
              <button
                onClick={() => setIsModalOpen(true)}
                class="w-full focus:text-teal-500 hover:text-teal-500 flex flex-col items-center justify-center inline-block text-center pt-2 pb-1"
              >
              <img
              src="https://img.icons8.com/ios/50/000000/add--v1.png"
              width="30px"
              alt="add"
              />
              <span class="tab tab-home block text-xs mt-1">Add Quantity</span>
              </button>

              <button
                onClick={() => setIsModalOpen2(true)}
                class="w-full focus:text-teal-500 hover:text-teal-500 flex flex-col items-center justify-center inline-block text-center pt-2 pb-1"
              >
                <img
                  src="https://img.icons8.com/ios/50/000000/add--v1.png"
                  width="30px"
                  alt="add"
                />
                <span class="tab tab-home block text-xs mt-1">Add Product</span>
              </button>
            </>
          )}

          
        </div>
      </section>
    </div>
  );
}
