import React, { useState } from "react";
import { useNavigate,  } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import styled from "styled-components";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [auth, setAuth] = useState(localStorage.getItem("isAuthenticated"));
  const [disabled, setDisabled] = useState(false);

  const navigate = useNavigate();

  const errorNotify = (err) => toast.error(`Error: ${err}`, { duration: 4000 });
  const loginSuccess = () => toast.success("Login Successful!", { duration: 4000 });

  const onSubmit = (event) => {
    if(disabled) {
      return;
    }
    setError(null);
    setDisabled(true);
    event.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: email,
      password: password,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(`${process.env.REACT_APP_API_URL}/auth/login`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        const res = JSON.parse(result);
        if(res.success) {
          localStorage.setItem("userEmail", email);
          localStorage.setItem("isAuthenticated", true);
          window.location.href="/dashboard";
          loginSuccess();
        }else {
          setError(res.error);
          errorNotify(res.error);
        }
        
      })
      .catch((error) => {
        console.log("error", error);
        setError(true);
      });
  };

  // if(auth) {
  //   window.location.href = "/dashboard";
  // }
  

  if (document.documentElement.clientWidth > 768) {
    return <MainDiv>You can only view this website on a mobile device</MainDiv>;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        {error && (
          <p
            style={{
              color: "red",
              fontSize: "1.2em",
              margin: "2em",
              fontWeight: "500",
            }}
          >
            An error occured. {error}
          </p>
        )}

        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Welcome back
          </h2>
        </div>
        <form className="mt-8 space-y-6" action="#" method="POST">
          <input type="hidden" name="remember" value="true"></input>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">
                Email address
              </label>
              <input
                id="email-address"
                name="email"
                type="email"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Email address"
                onChange={(event) => {setDisabled(false); setError(null); setEmail(event.target.value)}}
                autoComplete="nope"
              ></input>
            </div>
            <div>
              <label htmlFor="password" className="sr-only">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="Password"
                onChange={(event) => {setDisabled(false); setError(null); setPassword(event.target.value)}}
              ></input>
            </div>
          </div>

          <div>
            <button
              onClick={onSubmit}
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={disabled}
            >
              <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                {/* <!-- Heroicon name: solid/lock-closed --> */}
                <svg
                  className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
              {disabled && !error ? 'Signing in ...' : 'Sign in'}
            </button>
          </div>
          {/* <div className="flex items-center justify-between">
            <div className="text-sm">
              <a
                href="/forgot-password"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot password?
              </a>
            </div>
            <div className="text-sm">
              <a
                href="/register"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Don't have an account? Sign Up
              </a>
            </div>
          </div> */}
        </form>
      </div>
      <Toaster />
    </div>
  );
};

export default SignIn;

const MainDiv = styled.div`
  background-color: #ccd1e4;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
