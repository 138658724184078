import * as React from "react";
import { useNavigate } from "react-router-dom";

export default function LabelBottomNavigation({ setIsModalOpen }) {
  const [value, setValue] = React.useState("recents");
  const navigate = useNavigate();


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const signOut = () => {
    localStorage.removeItem("userEmail");
    localStorage.removeItem("isAuthenticated");
    navigate("/signin");
  }

  const email = localStorage.getItem("userEmail");


  return (
    <div class="w-full mt-36">
      <section
        id="bottom-navigation"
        class="md:hidden block fixed inset-x-0 bottom-0 z-10 bg-white shadow h-20 flex items-center justify-between"
      >
        {/* <section
        id="bottom-navigation"
        class="block fixed inset-x-0 bottom-0 z-10 bg-white shadow"
      > */}
        <div id="tabs" class="flex justify-between w-full">
          {/* <a
            href="/"
            class="w-full focus:text-teal-500 hover:text-teal-500 flex flex-col items-center justify-center inline-block text-center pt-2 pb-1"
          >
            <img
              src="https://img.icons8.com/windows/32/000000/home.png"
              width="30px"
              alt="home"
            />
            <span class="tab tab-explore block text-xs mt-1">Home</span>
          </a> */}

          {email !== 'njenga@neovets.com' && (
            <button
            onClick={() => setIsModalOpen(true)}
            class="w-full focus:text-teal-500 hover:text-teal-500 flex flex-col items-center justify-center inline-block text-center pt-2 pb-1"
            >
              <img
                src="https://img.icons8.com/ios/50/000000/add--v1.png"
                width="30px"
                alt="add"
              />
              <span class="tab tab-home block text-xs mt-1">Add Sale</span>
            </button>
          )}

          

          <a
            href="/inventory"
            class="w-full focus:text-teal-500 hover:text-teal-500 flex flex-col items-center justify-center inline-block text-center pt-2 pb-1"
          >
            <img
              src="https://img.icons8.com/material-outlined/24/000000/warehouse-1.png"
              width="30px"
              alt="warehouse"
            />
            <span class="tab tab-account block text-xs mt-1">Inventory</span>
          </a>
          

          <a
            onClick={signOut}
            class="w-full focus:text-teal-500 hover:text-teal-500 flex flex-col items-center justify-center inline-block text-center pt-2 pb-1"
          >
            <img src="https://img.icons8.com/external-dreamstale-lineal-dreamstale/32/000000/external-logout-ui-dreamstale-lineal-dreamstale.png"
             width="25px" alt="logout"/>
            <span class="tab tab-account block text-xs mt-1">Logout</span>
          </a>
        </div>
      </section>
    </div>
  );
}
