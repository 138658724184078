import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
// import PlusImage from "../resources/plus.png";
import AddInventoryQuantityModal from "../components/AddInventoryQuantityModal";
import InventoryBottomNavigation from "../components/InventoryBottomNavigation";
import AddNewInventoryProductModal from "../components/AddNewInventoryProductModal";
import ViewDetailsModal from "../components/ViewDetailsModal";
import toast, { Toaster } from "react-hot-toast";

export default function Inventory() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const notifyQuantity = () =>
    toast.success("Stock quantity updated successfully!");
  const notifyProduct = () => toast.success("New product added successfully!");

  useEffect(() => {
    const fetchData = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        var config = {
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/products`,
          headers: {
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxYjA4M2UyZDA2YWM5NTBlMDRmM2MyMyIsImlhdCI6MTY0MDk1MTM5OCwiZXhwIjoyNTA0OTUxMzk4fQ.-lIGUSspymZOp8lhqJj47h1AOANJqqfU9MoGMWfT_JM",
          },
        };

        const res = await axios(config);
        setProducts(res.data.products);
      } catch (error) {
        setIsError(true);
      }

      setIsLoading(false);
    };
    fetchData();
  }, []);

  const getSelectedItemDetails = (item) => {
    setSelectedItem(item);
    setViewDetailsModal(true);
  };

  if (isLoading) {
    return (
      <MainDiv style={{ height: "100vh" }}>
        <ItemsDiv>Loading...</ItemsDiv>
      </MainDiv>
    );
  }

  if (isError) {
    return (
      <MainDiv style={{ height: "100vh" }}>
        <ItemsDiv>Error!</ItemsDiv>
      </MainDiv>
    );
  }

  return (
    <MainDiv>
      <ItemsTitle>
        <p>Current Inventory</p>
      </ItemsTitle>
      <ItemsDiv>
        <>
          {products?.length > 0 ? (
            <>
              {products.map((product, i) => (
                <div key={i} onClick={() => getSelectedItemDetails(product)}>
                  <h4>
                    <span style={{ marginRight: ".5em" }}>#</span>
                    {product.name}
                  </h4>
                  <p>{product.quantity} items</p>
                </div>
              ))}
            </>
          ) : (
            <div
              style={{
                height: "80vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h4>No products in inventory</h4>
            </div>
          )}
        </>
      </ItemsDiv>
      {/* <AddButton onClick={() => setIsModalOpen(true)}>
        <img src={PlusImage} alt=""></img>
      </AddButton> */}
      {isModalOpen && (
        <AddInventoryQuantityModal
          setIsModalOpen={setIsModalOpen}
          notify={notifyQuantity}
        />
      )}
      {isModalOpen2 && (
        <AddNewInventoryProductModal
          setIsModalOpen2={setIsModalOpen2}
          notify={notifyProduct}
        />
      )}
      {viewDetailsModal && (
        <ViewDetailsModal
          setIsModalOpen={setViewDetailsModal}
          item={selectedItem}
        />
      )}

      <InventoryBottomNavigation
        setIsModalOpen={setIsModalOpen}
        setIsModalOpen2={setIsModalOpen2}
      />

      <Toaster />
    </MainDiv>
  );
}

const MainDiv = styled.div`
  background-color: #ccd1e4;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ItemsTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  height: 7vh;
  width: 100%;
  padding: 2em;

  > p {
    font-size: 1.2em;
    font-weight: 400;
  }
`;

const ItemsDiv = styled.div`
  display: flex;
  align-items: left;
  justify-content: center;
  height: auto;
  width: 100%;
  flex-flow: column nowrap;

  > div {
    height: auto;
    width: 100%;
    padding-left: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    flex-flow: row nowrap;

    :nth-of-type(odd) {
      background: #feece9;
    }

    > h4 {
      font-size: 1em;
    }
    > p {
      font-size: 1em;
    }
  }
`;

// const AddButton = styled.button`
//   background-color: #2f3a8f;
//   color: white;
//   height: 3.5em;
//   width: 3.5em;
//   border: none;
//   border-radius: 50px;
//   padding: 10px;
//   font-size: 1.2rem;
//   font-weight: bold;
//   cursor: pointer;
//   transition: all 0.2s ease-in-out;
//   position: fixed;
//   bottom: 3px;
//   margin-bottom: 1em;
// `;
