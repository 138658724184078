import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import RecordSalesModal from "../components/RecordSalesModal";
import ViewDetailsModal from "../components/ViewDetailsModal";
import BottomNavigation from "../components/BottomNavigation";
import toast, { Toaster } from "react-hot-toast";
import SelectorBox from "../components/SelectorBox";

const people = [
  {
    id: 1,
    name: 'Today',
  },
  {
    id: 2,
    name: 'Past Week',
  },
  {
    id: 3,
    name: 'Past Month',
  }
  
]

function Main() {
  const [sales, setSales] = useState([]);
  const [revenue, setRevenue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selected, setSelected] = useState(people[0])
  const [viewDetailsModal, setViewDetailsModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);


  const notify = () => toast.success("Sale recorded successfully!");

  useEffect(() => {
    fetchData();

    if (selected.id === 1) { 
      getTotalSales();
    } 
    if (selected.id === 2) { 
      getTotalSalesForLastWeek();
    }
    if (selected.id === 3) { 
      getTotalSalesForLastMonth();
    } 
    
  }, [selected]);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/sales`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxYjA4M2UyZDA2YWM5NTBlMDRmM2MyMyIsImlhdCI6MTY0MDk1MTM5OCwiZXhwIjoyNTA0OTUxMzk4fQ.-lIGUSspymZOp8lhqJj47h1AOANJqqfU9MoGMWfT_JM",
        },
        
      };

      const res = await axios(config, { withCredentials: true });
      setSales(res.data.sales);
    } catch (error) {
      setIsError(true);
    }

    setIsLoading(false);
  };

  const getTotalSales = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        var config = {
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/revenue/revenue-today`,
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxYjA4M2UyZDA2YWM5NTBlMDRmM2MyMyIsImlhdCI6MTY0MDk1MTM5OCwiZXhwIjoyNTA0OTUxMzk4fQ.-lIGUSspymZOp8lhqJj47h1AOANJqqfU9MoGMWfT_JM",
          },
          referrerPolicy: 'origin', 
        };

        const res = await axios(config);
        setRevenue(res.data.total);
      } catch (error) {
        setIsError(true);
      }

      setIsLoading(false);
    };

   const getTotalSalesForLastWeek = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        var config = {
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/revenue/revenue-last-week`,
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxYjA4M2UyZDA2YWM5NTBlMDRmM2MyMyIsImlhdCI6MTY0MDk1MTM5OCwiZXhwIjoyNTA0OTUxMzk4fQ.-lIGUSspymZOp8lhqJj47h1AOANJqqfU9MoGMWfT_JM",
          },
          referrerPolicy: 'origin', 
        };

        const res = await axios(config);
        setRevenue(res.data.total);
      } catch (error) {
        setIsError(true);
        console.log(error)
      }

      setIsLoading(false);
  };
  
   const getTotalSalesForLastMonth = async () => {
      setIsError(false);
      setIsLoading(true);

      try {
        var config = {
          method: "get",
          url: `${process.env.REACT_APP_API_URL}/revenue/revenue-last-month`,
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization:
              "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxYjA4M2UyZDA2YWM5NTBlMDRmM2MyMyIsImlhdCI6MTY0MDk1MTM5OCwiZXhwIjoyNTA0OTUxMzk4fQ.-lIGUSspymZOp8lhqJj47h1AOANJqqfU9MoGMWfT_JM",
          },
          referrerPolicy: 'origin', 
        };

        const res = await axios(config);
        setRevenue(res.data.total);
      } catch (error) {
        setIsError(true);
      }

      setIsLoading(false);
    };

  const getSelectedItemDetails = (item) => {
    setSelectedItem(item);
    setViewDetailsModal(true);
  }

  const userEmail = localStorage.getItem("userEmail");


  if (document.documentElement.clientWidth > 768) {
    return <MainDiv>You can only view this website on a mobile device</MainDiv>;
  }

  if (isLoading) {
    return (
      <MainDiv
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Loading...
      </MainDiv>
    );
  }

  if (isError) {
    return (
      <MainDiv
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <p style={{ color: "crimson" }}>An error occured</p>
      </MainDiv>
    );
  }

  return (
    <MainDiv>
      <TopDiv>
        <h1>Sales</h1>
       {userEmail !== 'kibuika@neovets.com' ? <></> : <div className="w-40"><SelectorBox people={people} selected={selected} setSelected={setSelected}/></div>} 
      </TopDiv>
      <MoneyDiv>
        <p>
          <span style={{ fontSize: ".6em" }}>KES</span> {revenue}
        </p>
      </MoneyDiv>

      <ItemsTitle>
        <p>Recently sold</p>
      </ItemsTitle>
      <ItemsDiv>
        <>
          {sales.length > 0 ? (
            <>
              {sales.map((sale, i) => (
                <div key={i} onClick={() => getSelectedItemDetails(sale)}>
                  <h4>
                    <span style={{ marginRight: ".5em" }}>#</span>
                    {sale.name}
                  </h4>
                  <p>Kes. {sale.amount}</p>
                </div>
              ))}
            </>
          ) : (
            <div
              style={{
                height: "60vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h4>No sales yet</h4>
            </div>
          )}
        </>
      </ItemsDiv>
      <BottomNavigation setIsModalOpen={setIsModalOpen} />
      {/* <AddButton onClick={() => setIsModalOpen(true)}>
        <img src={PlusImage} alt=""></img>
      </AddButton> */}
      {isModalOpen && (
        <RecordSalesModal
          setIsModalOpen={setIsModalOpen}
          createToast={notify}
        />
      )}
      {viewDetailsModal && (
        <ViewDetailsModal setIsModalOpen={setViewDetailsModal} item={selectedItem}/>
      )}
      <Toaster />
    </MainDiv>
  );
}

export default Main;

const MainDiv = styled.div`
  background-color: #ccd1e4;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TopDiv = styled.div`
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  padding: 1em 2em 1em 2em;
  height: 10vh;

  > h1 {
    font-size: 1.4em;
    font-weight: bold;
  }

  > h2 {
    font-size: 0.9em;
  }
`;

const MoneyDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  height: 15vh;
  width: 100%;
  padding: 2em;
  > p {
    font-size: 3em;
    font-weight: 700;
  }
`;

const ItemsTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  height: 7vh;
  width: 100%;
  padding: 2em;

  > p {
    font-size: 1.2em;
    font-weight: 400;
  }
`;

const ItemsDiv = styled.div`
  display: flex;
  align-items: left;
  justify-content: center;
  height: auto;
  width: 100%;
  flex-flow: column nowrap;

  > div {
    height: auto;
    width: 100%;
    padding-left: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    flex-flow: row nowrap;

    :nth-of-type(odd) {
      background: #feece9;
    }

    > h4 {
      font-size: 1em;
    }
    > p {
      font-size: 1em;
    }
  }
`;
