import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function AddNewInventoryProductModal({ setIsModalOpen2, notify }) {
  const [isError, setIsError] = useState(false);
  const [allCategories, setAllCategories] = useState([]);
  const [productName, setProductName] = useState("");
  const [buyingPrice, setBuyingPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    name: "Select Category",
    id: 0,
  });

  useEffect(() => {
    getCategories();
  },[])

  const handleUpload = () => {
    if(disabled) {
      return;
    }
    setDisabled(true);
    let newBuyingPrice = parseInt(buyingPrice);
    let newQuantity = parseInt(quantity);
    var data = JSON.stringify({
      name: productName,
      description: productName,
      buyingPrice: newBuyingPrice,
      quantity: newQuantity,
      categoryId: selectedCategory.id,
    });
    var config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}/products/add`,
      headers: {
        Authorization:
          "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxYjA4M2UyZDA2YWM5NTBlMDRmM2MyMyIsImlhdCI6MTY0MDk1MTM5OCwiZXhwIjoyNTA0OTUxMzk4fQ.-lIGUSspymZOp8lhqJj47h1AOANJqqfU9MoGMWfT_JM",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(() => {
        notify();
      })
      .catch((error) =>{
        console.log(error);
        setIsError(true);
      });
    setIsModalOpen2(false);
  };

  const getCategories = async() => {
    try {
      var config = {
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/categories/`,
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxYjA4M2UyZDA2YWM5NTBlMDRmM2MyMyIsImlhdCI6MTY0MDk1MTM5OCwiZXhwIjoyNTA0OTUxMzk4fQ.-lIGUSspymZOp8lhqJj47h1AOANJqqfU9MoGMWfT_JM",
          "Content-Type": "application/json",
        },
      };

      const res = await axios(config);
      let cats = [{name: "Select Category", id: 0}, ...res?.data?.categories?.map(item => ({name: item.name, id: item.categoryId}))];
      setAllCategories(cats);
    } catch (error) {
      setIsError(true);
    }
  }
  return (
    <div
      class="fixed z-10 inset-0 h-full overflow-y-auto w-full -mt-32"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{ width: "100%" }}
    >
      <div class="flex items-end justify-center h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0 w-full">
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity "
          aria-hidden="true"
        ></div>

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div class="h-auto inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="flex flex-col items-center justify-center h-auto mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3
                  class="text-lg leading-6 font-medium text-gray-900"
                  id="modal-title"
                >
                  Add New Product. Add New Product.
                </h3>
                {isError && (
                  <p
                    style={{
                      color: "red",
                      fontSize: "1.2em",
                      margin: "2em",
                      fontWeight: "500",
                    }}
                  >
                    An error occured. Please try again later.
                  </p>
                )}
              </div>
            </div>
            <>
              <div className="mt-4 w-full flex flex-col items-center justify-center ">
              <div class="m-2 flex flex-col items-center justify-center w-full">
                <Listbox
                  value={selectedCategory.name}
                  onChange={setSelectedCategory}
                >
                  <div className="mt-1 flex flex-col items-center justify-center w-full">
                    <Listbox.Button className=" relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                      <span className="flex items-center">
                        <span className="ml-3 block truncate">
                          {selectedCategory.name}
                        </span>
                      </span>
                      <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute mt-1 z-50 w-full bg-white shadow-lg max-h-56 rounded-md py-1 pl-3 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                        {allCategories?.map((item) => (
                          <Listbox.Option
                            className={({ active }) =>
                              classNames(
                                active
                                  ? "text-white bg-indigo-600"
                                  : "text-gray-900",
                                "cursor-default select-none relative py-2 pl-3 pr-9"
                              )
                            }
                            value={{ name: item.name, id: item.id }}
                            key={item.id}
                          >
                            {({ selected, active }) => (
                              <>
                                <div className="flex items-center">
                                  <span
                                    className={classNames(
                                      selected
                                        ? "font-semibold"
                                        : "font-normal",
                                      "ml-3 block truncate"
                                    )}
                                  >
                                    {item.name}
                                  </span>
                                </div>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? "text-white" : "text-indigo-600",
                                      "absolute inset-y-0 right-0 flex items-center pr-4"
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
                <input
                  id="name"
                  name="name"
                  type="string"
                  required
                  className="mt-4 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Product Name"
                  onChange={(e) => setProductName(e.target.value)}
                ></input>
                <input
                  id="buyingPrice"
                  name="buyingPrice"
                  type="number"
                  required
                  className="mt-4 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Buying Price"
                  onChange={(e) => setBuyingPrice(e.target.value)}
                ></input>
                <input
                  id="quantity"
                  name="quantity"
                  type="number"
                  required
                  className="mt-4 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Quantity"
                  onChange={(e) => setQuantity(e.target.value)}
                ></input>
              </div>

              <div class="mt-4 bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={() => setIsModalOpen2(false)}
                  type="button"
                  class=" w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-400 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpload}
                  type="button"
                  class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  disabled={disabled}
                >
                  {disabled ? 'Saving...' : 'Save' }
                </button>
              </div>
            </>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewInventoryProductModal;
