import React from "react";

export default function ViewDetailsModal({ setIsModalOpen, item }) {
  console.log(item)
  let date = item.createdAt.split("T")[0];
  return (
    <div
    class="fixed z-10 inset-0 h-full overflow-y-auto w-full -mt-32"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    style={{ width: "100%" }}
  >
    <div class="flex items-end justify-center h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0 w-full">
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity "
        aria-hidden="true"
      ></div>

      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
      >
        &#8203;
      </span>

      <div class="h-auto inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="flex flex-col items-center justify-center h-auto mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                class="text-lg leading-6 font-medium text-gray-900"
                id="modal-title"
              >
                View Details. View Details. View Details
              </h3>
            </div>
          </div>
          <div className="mt-4 w-full flex flex-col items-left justify-center ">
          <p>Name: <strong style={{}}>{item.name}</strong></p>
          {item.categoryId && <p>Category ID: <strong>{item.categoryId}</strong></p>}
          {item.amount && <p>Sold At: <strong>{item.amount}</strong></p>}
          {item.quantity && <p>Quantity: <strong>{item.quantity}</strong></p>}
          {item.buyingPrice && <p>Bought At: approx. <strong>{Math.round(item.buyingPrice)}</strong> per item</p>}
          {item.buyingPrice ? <p>Last added on: <strong>{item.updatedAt?.split('T')[0] || "-"}</strong></p> : <p>Recorded On: <strong>{date}</strong></p>}
          </div>
          
         
        </div>
        <div class="mt-4 bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            onClick={() => setIsModalOpen(false)}
            type="button"
            class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            // disabled={disabled}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  )
}
