import React, { useEffect} from "react";
import "./App.css";
// import Header from "./layout/header";
import Main from "./pages/Main";
import { Route, Routes, Navigate, useLocation, Outlet, useNavigate } from "react-router-dom";
import SignIn from "./auth/SignIn";
import Inventory from "./pages/Inventory";
import toast, { Toaster } from "react-hot-toast";
import styled from "styled-components";
import useWebInstallPrompt from "./shared/useWebInstallPrompt";

const notify = () => toast.error("You seem to be offline!", { duration: 4000 });

function App() {
  const [offline, setOffline] = React.useState(false);
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();
  const navigate = useNavigate();


    const checkStatus = () => {
      var status = navigator.onLine ? true : false;
      setOffline(!status);
      if (status === false) {
        notify();
      }
    }

  // effects
  useEffect(() => {
    // checkStatus();
    // let auth = localStorage.getItem("auth");
    // if (auth) {
    //     navigate("/dashboard")
    // }
  }, []);

  if (offline) {
    return (
      <MainDiv
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        The application is offline, check your internet connection and try
        again.
      </MainDiv>
    );
  }

  let auth = localStorage.getItem("auth");

  // if (!webInstallPrompt) {
    return (
      <div className="App">
        <div>
          <Routes>
            <Route exact path="/" element={<SignIn auth={auth}/>} />
            <Route path="/signin" element={<SignIn auth={auth}/>} />
            <Route element={<RequireAuth />} >
              <Route path="/dashboard" element={<Main auth={auth}/>} />
              <Route path="/inventory" element={<Inventory auth={auth}/>}/>
            </Route>
          </Routes>
        </div>
      </div>
    );
  // }
  // return (
  //   <div
  //     class="fixed z-10 inset-0 h-full overflow-y-auto w-full -mt-28"
  //     aria-labelledby="modal-title"
  //     role="dialog"
  //     aria-modal="true"
  //     style={{ width: "100%" }}
  //   >
  //     <div class="flex items-end justify-center h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0 w-full">
  //       <div
  //         class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity "
  //         aria-hidden="true"
  //       ></div>

  //       <span
  //         class="hidden sm:inline-block sm:align-middle sm:h-screen"
  //         aria-hidden="true"
  //       >
  //         &#8203;
  //       </span>

  //       <div class="h-auto inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all  sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
  //         <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
  //           <div class="sm:flex sm:items-start">
  //             <div class="flex flex-col items-center justify-center h-auto mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
  //               <h3
  //                 class="text-lg leading-6 font-medium text-gray-900"
  //                 id="modal-title"
  //               >
  //                 Install the Tory App
  //               </h3>
  //             </div>
  //           </div>
  //         </div>
  //         <div class="mt-4 bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
  //           <button
  //             onClick={handleWebInstallDeclined}
  //             type="button"
  //             class=" w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-400 text-base font-medium text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
  //           >
  //             Cancel
  //           </button>
  //           <button
  //             onClick={handleWebInstallAccepted}
  //             type="button"
  //             class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
  //           >
  //             Save
  //           </button>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
}

function RequireAuth() {
  let auth = localStorage.getItem("isAuthenticated");
  let location = useLocation();

  if (!auth) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signin" state={{ from: location }} />;
  }

  return <Outlet />;
}

export default App;

const MainDiv = styled.div`
  background-color: #ccd1e4;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
